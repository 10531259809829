import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import { translate } from '@/core/plugins/i18n';
import ApiService from '@/core/services/ApiService';
import SalesToBillRow from '@/models/bill/SalesToBillRow';
import PaginationRequest from '@/models/general/PaginationRequest';
import PaginationResponse from '@/models/general/PaginationResponse';
import { AxiosResponse } from "axios";
import GeneralService from "../interfaces/GeneralService";
import MessageService from "../interfaces/MessageService";
import SwalMessageService from "../SwalMessageService";
import TableReceptionsService from './interfaces/TableReceptionsService';
import FiltersReceptions from '@/models/receptions/filters/FilterReceptions';
import ReceptionRow from '@/models/receptions/ReceptionRow';
import { ReceptionStatus } from '@/core/enums/receptions-status';
import TableReceptionDetailsService from './interfaces/TableReceptionDetailsService';
import FiltersReceptionDetails from '@/models/receptions/filters/FilterReceptionDetails';
import ReceptionDetailRow from '@/models/receptions/ReceptionDetailRow';

export default class ReceptionsService implements GeneralService, TableReceptionsService, TableReceptionDetailsService {
    
    apiController = "/PurchaseOrders";
    messageService: MessageService = new SwalMessageService();

    async searchTableReceptions(pagination: PaginationRequest, filters: FiltersReceptions): Promise<AxiosResponse<PaginationResponse<ReceptionRow>>> {
        let supplierIdsFilters = '';
        if (filters.supplierIds) { 
            filters.supplierIds.every(supplierId => {
                supplierIdsFilters += (supplierId && supplierId != '' ? `&Criteria.SupplierIds=${supplierId}` : '')
                return true;
            })
        }
       
        return ApiService.get<PaginationResponse<SalesToBillRow>>(this.apiController, 
            `GetPagedList?`//puede cambiar esta ruta
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.folio && filters.folio != '' ? `&Criteria.Folio=${filters.folio}` : '')
            + (filters.supplierFolio && filters.supplierFolio != '' ? `&Criteria.SupplierFolio=${filters.supplierFolio}` : '')
            + (supplierIdsFilters && supplierIdsFilters != '' ? supplierIdsFilters : '')
            + (filters.startDate && filters.startDate != '' ? `&Criteria.InitialDate=${filters.startDate}` : '')
            + (filters.endDate && filters.endDate != '' ? `&Criteria.FinalDate=${filters.endDate}` : '')
            + (Object.values(ReceptionStatus).includes(filters.filterStatus ?? '') ? `&Criteria.ReceptionStatus=${filters.filterStatus}` : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchTableReceptionDetails(receptionId: string, pagination: PaginationRequest, filters: FiltersReceptionDetails): Promise<AxiosResponse<PaginationResponse<ReceptionDetailRow>>> {      
        // let articleIdsFilters = '';
        // if (filters.articleIds) { 
        //     filters.articleIds.every(articleId => {
        //         articleIdsFilters += (articleId && articleId != '' ? `&Criteria.ProductsIds=${articleId}` : '')
        //         return true;
        //     })
        // }
        // return ApiService.get<PaginationResponse<SalesToBillRow>>(this.apiController, 
        //     `Detail/`
        //     + `GetPagedList/${receptionId}?`
        //     + `CurrentPage=${pagination.currentPage}`
        //     + `&PageSize=${pagination.pageSize}`
        //     + (filters.articleCode && filters.articleCode != '' ? `&Criteria.Id=${filters.articleCode}` : '')
        //     + (filters.productName && filters.productName.trim() != '' ? `&Criteria.productName=${filters.productName}` : '')
        //     + (articleIdsFilters && articleIdsFilters != '' ? articleIdsFilters : '')
        // ).then( resp => {
        //     return resp;
        // }).catch(error => {
        //     getMessageError(error, this.messageService);
        //     return { data: null} as AxiosResponse;
        // });

        let articleIdsFilters = '';
        if (filters.articleIds) { 
            filters.articleIds.every(articleId => {
                articleIdsFilters += (articleId && articleId != '' ? `&Criteria.ProductsIds=${articleId}` : '')
                return true;
            })
        }
        return ApiService.get<PaginationResponse<SalesToBillRow>>("/ReceiptPurchaseOrders", 
            `PurchaseOrderDetail/`
            + `GetPagedList/${receptionId}?`
            + `CurrentPage=${pagination.currentPage}`
            + `&PageSize=${pagination.pageSize}`
            + (filters.articleCode && filters.articleCode != '' ? `&Criteria.Id=${filters.articleCode}` : '')
            + (filters.productName && filters.productName.trim() != '' ? `&Criteria.productName=${filters.productName}` : '')
            + (articleIdsFilters && articleIdsFilters != '' ? articleIdsFilters : '')
        ).then( resp => {
            return resp;
        }).catch(error => {
            getMessageError(error, this.messageService);
            return { data: null} as AxiosResponse;
        });
    }

    async searchReceptionInfo(receptionId: string): Promise<AxiosResponse<ReceptionRow>> {
        return ApiService.get(`${this.apiController}/${receptionId}`)
        .then(res => {
            return res;
        }).catch(reject => {
            getMessageError(reject, this.messageService);
                return { data: null} as AxiosResponse;
        });
     }
}