import Modules from '@/store/enums/Modules';
import store from '@/store';
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import TableReceptionsState from '../../models/TableReceptionsState';
import BillCreateRow from '@/models/bill/BillCreatedRow';
import PaginationRequest from '@/models/general/PaginationRequest';
import { Mutations } from '@/store/commons/MutationsTable';
import { SaveValuesToStore } from '@/core/shared/AssingProps';
import { Actions } from '@/store/commons/ActionsTable';
import ReceptionRow from '@/models/receptions/ReceptionRow';
import FiltersReceptions from '@/models/receptions/filters/FilterReceptions';
import ReceptionsService from '@/core/services/receptions/ReceptionsService';
import TableReceptionsService from '@/core/services/receptions/interfaces/TableReceptionsService';
import OptionSelect from '@/models/shared/OptionSelect';
import SuppliersComboService from '@/core/services/suppliers/SuppliersComboService';

enum MutationsOwner { 
    SET_SELECT_ROW = "SET_SELECT_ROW"
    , RESET_SELECT_ROW = "RESET_SELECT_ROW"
}

@Module({dynamic: true, store, namespaced: true, name: Modules.TableReceptionModule})
class TableReceptionsModule extends VuexModule implements TableReceptionsState {
    receptionSelected = defaultReception
    selects = {
        supplierOptions: [] as OptionSelect[]
    };
    records = [] as ReceptionRow[];
    totalRecords = 0;
    pagination = {
        currentPage: 1
        , pageSize: 10//defecto
    } as PaginationRequest
    filters = {
        endDate:  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 15).toISOString()
        , startDate: new Date().toISOString()
        , supplierFolio: ''
        , supplierIds: []
        , filterStatus: undefined
        , folio: ''
        , productsIds: ''
    } as FiltersReceptions;
    loading = false;

    get getSelectedRow(): ReceptionRow { 
        return this.receptionSelected;
    }
    get getRecordsTable(): ReceptionRow[] { 
        return this.records;
    }
    @Mutation
    [MutationsOwner.SET_SELECT_ROW](value: ReceptionRow | undefined) {
        if (value) { 
            this.receptionSelected = value;
        }
    }
    @Mutation
    [MutationsOwner.RESET_SELECT_ROW](){
        this.receptionSelected = defaultReception;
    }

    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [Mutations.SET_TABLE_DATA](table: ReceptionRow[]){
        this.records = table;
    }
     @Mutation
    [Mutations.RESET_FILTERS](){
         this.filters = {
            endDate: ''
            , startDate: ''
            , supplierFolio: ''
            , supplierIds: []
            , filterStatus: undefined
            , folio: ''
            , productsIds: ''
         } as FiltersReceptions;
    }
    @Mutation
    [Mutations.SET_PAGINATION](pagination: Partial<PaginationRequest>){
        SaveValuesToStore(this.pagination, pagination);
    }
    @Mutation
    [Mutations.SET_FILTERS](filters: Partial<FiltersReceptions>) {      
        SaveValuesToStore(this.filters, filters);
    }
    @Mutation
    [Mutations.SET_TOTAL_RECORDS](totalRecords: number){
        this.totalRecords = totalRecords;
    }

     /**
     * Se obtienen los datos de la tabla de facturas creadas mediante la paginación
     */
     @Action
     async [Actions.SEARCH_TABLE_DATA](){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
         
         const resp = (await (service.searchTableReceptions(this.pagination, this.filters)
             .finally(() => this.context.commit(Mutations.SET_VALUE_LOADING, false)))).data ??
             {
                 data: [] as ReceptionRow[]
                , totalPages: 0
                , currentPage: this.pagination.currentPage
             }
         this.context.commit(Mutations.SET_TABLE_DATA, resp.data);
         this.context.commit(Mutations.SET_PAGINATION,{ currentPage: resp.currentPage });
         this.context.commit(Mutations.SET_TOTAL_RECORDS, resp.totalPages);
    }
 
     /**
        * Realiza un busqueda con la nueva paginación
        * @param pagination paginación de la tabla
        */
     @Action
     async [Actions.CHANGE_PAGE](pagination: Partial<PaginationRequest>){
           this.context.commit(Mutations.SET_PAGINATION, pagination);
           this.context.dispatch(Actions.SEARCH_TABLE_DATA);
     }
 
     /**
      * Filtros que se aplican y despues se hace la busqueda de los datos
      * @param filters filtros nuevos
      */
     @Action
     async [Actions.UPDATE_FILTERS](filters: Partial<FiltersReceptions>) {
         //aqui se cambia el filtro y se buscan los datos de nuevo de la tabla
         this.context.commit(Mutations.SET_FILTERS, filters);
         this.context.commit(Mutations.SET_PAGINATION, {
           currentPage: 1 //si cambiamos filtros buscamos sobre la pagina uno
         });
         this.context.dispatch(Actions.SEARCH_TABLE_DATA);
    }
    
     @MutationAction
     async getComboSupplier(filterName: string) {
         const comboOptions = await supliersService.searchCombo(filterName);
         const selects = { ...this.selects }
        if(filterName.trim() == '') return  { selects: { ...selects } }
        
        return { selects: { ...selects, supplierOptions: comboOptions.data ?? [] } }
    }

     @Action
     async searchInfoReceptionSelected(id: string) {
         return service.searchReceptionInfo(id)
             .then((reception) => { 
                this.context.commit(MutationsOwner.SET_SELECT_ROW, reception.data ?? defaultReception);

             })
    }
} 

const service = new ReceptionsService();
const supliersService = new SuppliersComboService()
const defaultReception = {
        id: ''
        , folio: ''
        , purchaseOrderSupplierFolio: ''
        , receptionStatus: ''
        , status: -1
        , statusName: ''
        , statusReceptionName: ''
        , supplierName: ''
        , createdAt: undefined
        , dateOfDelivery: undefined
    } as ReceptionRow;
export default getModule(TableReceptionsModule);
