import OptionSelect from "@/models/shared/OptionSelect";
import { translate } from "../plugins/i18n";

/**
 * Estatus disponibles de la recepción
 */
 export enum ReceptionStatus {
    OnHold = 0//En espera
    , PartiallyAssorted//Parcialmente surtido
    , Assortment//surtido
}


/**
 * Opciones según el enumerador
 * @returns lISTA DE OPCIONES
 */
export function getOptions(): OptionSelect[] {
    return [
        {
            id: ReceptionStatus.OnHold
            , name: translate("ENUMS.RECEPTIONS_STATUS.OnHold")
        },
        {
            id: ReceptionStatus.PartiallyAssorted
            , name: translate("ENUMS.RECEPTIONS_STATUS.PartiallyAssorted")
        },
        {
            id: ReceptionStatus.Assortment
            , name: translate("ENUMS.RECEPTIONS_STATUS.Assortment")
        }
    ]
}